import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

import { Frame, withHover } from "framer"

// Tvorba komponentu s hover funkcionalitou
const HoverableFrame = withHover(Frame)

export function ChangeTextColorWithHover() {
    return (
        <HoverableFrame
            size={300}
            background={null}
            center
            whileHover={{ color: "orange" }}
            initial={{ color: "black" }}
        >
            <Frame
                size={"auto"}
                background={null}
                text={"Prejdite cez mňa!"}
                center
            />
        </HoverableFrame>
    )
}
